export const ENGINEER_LEVEL = {
  SENIOR: "SENIOR",
  MIDDLE: "MIDDLE",
  JUNIOR: "JUNIOR",
};

export const ENGINEER_LEVEL_LIST = [
  { label: "Старший", value: ENGINEER_LEVEL.SENIOR },
  { label: "Без уровня", value: ENGINEER_LEVEL.MIDDLE },
  { label: "Младший", value: ENGINEER_LEVEL.JUNIOR },
];

export const getEngineerLevelLabel = (engineerLevel) => {
  if (!engineerLevel) return "-";

  return ENGINEER_LEVEL_LIST.find(
    (listValue) => listValue.value === engineerLevel
  ).label;
};
