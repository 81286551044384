<template>
  <div class="p-grid p-justify-center p-formgrid">
    <div class="p-col-6">
      <h3>Создать профиль</h3>
      <div class="card p-fluid">
        <form @submit.prevent="submitForm">
          <div class="p-field">
            <label for="name">Имя</label>
            <InputText
              id="name"
              v-model.trim="name.val"
              :required="true"
              @blur="clearValidity('name')"
              :class="!name.isValid ? 'p-invalid' : ''"
            />
          </div>
          <div class="p-field">
            <label for="lastname">Фамилия</label>
            <InputText
              id="lastname"
              v-model.trim="lastname.val"
              :required="true"
              @blur="clearValidity('lastname')"
              :class="!lastname.isValid ? 'p-invalid' : ''"
            />
          </div>
          <div class="p-field">
            <label for="email">E-mail</label>
            <InputText
              type="email"
              id="email"
              @blur="clearValidity('email')"
              v-model.trim="email.val"
            />
          </div>
          <div class="p-field">
            <label for="phone">Телефон</label>
            <InputMask
              mask="+7-999-999-99-99"
              id="phone"
              v-model.trim="phone.val"
              :required="true"
              @blur="clearValidity('phone')"
              :class="!phone.isValid ? 'p-invalid' : ''"
              :autoClear="false"
            />
          </div>
          <div class="p-field">
            <label for="role">Тип профиля</label>
            <Dropdown
              id="role"
              v-model="role.val"
              :options="roleList"
              :required="true"
              optionLabel="name"
              placeholder="Выберите тип профиля"
              @blur="clearValidity('role')"
              :class="!role.isValid ? 'p-invalid' : ''"
            />
          </div>
          <div class="p-field" v-if="role?.val?.value === ROLE.OPERATOR">
            <label for="ephone">Расширенный телефон</label>
            <InputText
              id="ephone"
              v-model.trim="extensionPhone.val"
              @blur="clearValidity('extensionPhone')"
              :autoClear="false"
            />
          </div>
          <div class="p-field">
            <label for="countriesAndRegions">Выберите регион</label>
            <MultiSelect
              v-model="selectedGroupedCities.val"
              :options="groupedCities"
              optionLabel="label"
              optionGroupChildren="items"
              optionGroupLabel="label"
              placeholder="Выберите регионы"
              :class="!selectedGroupedCities.isValid ? 'p-invalid' : ''"
            >
              <template #optiongroup="slotProps">
                <div class="p-d-flex p-ai-center country-item">
                  <img
                    src="assets/layout/flags/flag_placeholder.png"
                    :class="'flag flag-' + slotProps.option.code.toLowerCase()"
                    class="p-mr-2"
                    width="18"
                  />
                  <div>{{ slotProps.option.label }}</div>
                </div>
              </template>
            </MultiSelect>
          </div>
          <div v-if="role?.val?.value === ROLE.ENGINEER">
            <div class="p-field">
              <label for="dep">Филиал</label>
              <Dropdown
                v-model="dep.val"
                :options="groupedCities"
                optionValue="label"
                optionLabel="label"
                optionGroupChildren="items"
                optionGroupLabel="label"
                placeholder="Выберите филиал"
                :class="!dep.isValid ? 'p-invalid' : ''"
              >
                <template #optiongroup="slotProps">
                  <div class="p-d-flex p-ai-center country-item">
                    <img
                      src="assets/layout/flags/flag_placeholder.png"
                      :class="
                        'flag flag-' + slotProps.option.code.toLowerCase()
                      "
                      class="p-mr-2"
                      width="18"
                    />
                    <div>{{ slotProps.option.label }}</div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="p-field">
              <label>Класс инженера</label>
              <Dropdown
                v-model="engineerLevel.val"
                :options="ENGINEER_LEVEL_LIST"
                optionValue="value"
                optionLabel="label"
                placeholder="Выберите класс инженера"
                :class="!engineerLevel.isValid ? 'p-invalid' : ''"
              />
            </div>
          </div>
          <div v-if="role?.val?.value === ROLE.SALES_MANAGER">
            <div class="p-field">
              <label for="company">Выберите компании</label>
              <Dropdown
                :filter="true"
                v-model="selectedCompany"
                :options="companies"
                placeholder="Нажмите, чтобы выбрать клиента для привязки к профилю"
                optionLabel="nameText"
                emptyFilterMessage="Совпадений не найдено"
              >
                <template #option="slotProps">
                  <div class="employee-item">
                    <div>
                      {{ slotProps.option.nameText }}
                    </div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="p-field">
              <label v-if="companiesForProfile.length"
                >Выбранные компании</label
              >
              <div
                v-for="(company, index) in companiesForProfile"
                :key="index"
                class="p-d-flex p-ai-center p-jc-between"
              >
                <div>{{ index + 1 }}. {{ company.name }}</div>
                <Button
                  @click="deleteCompany(index)"
                  class="pi pi-times p-button-danger delete-button"
                />
              </div>
            </div>
          </div>
          <Button
            label="Создать профиль"
            @click="submitForm"
            :disabled="isLoading"
          ></Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";
import { ROLE } from "@/models/roles";
import { ENGINEER_LEVEL_LIST } from "@/models/engineerLevel";

export default {
  name: "UserCreate",
  data() {
    return {
      ROLE,
      ENGINEER_LEVEL_LIST,
      name: {
        val: "",
        isValid: true,
      },
      lastname: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
        isValid: true,
      },
      extensionPhone: {
        val: null,
        isValid: true,
      },
      role: {
        val: null,
        isValid: true,
      },
      dep: {
        val: null,
        isValid: true,
      },
      engineerLevel: {
        val: null,
        isValid: true,
      },
      isLoading: false,
      formIsValid: false,
      groupedCities: [
        {
          label: "Россия",
          code: "RU",
          items: [],
          regionsForLoad: [],
        },
        {
          label: "Казахстан",
          code: "KZ",
          items: [],
          regionsForLoad: [],
        },
      ],
      selectedGroupedCities: {
        val: [],
        isValid: true,
      },
      selectedCompany: null,
      companiesForProfile: [],
    };
  },
  async created() {
    if (!this.$store.getters["data/regions"].length) {
      await this.$store.dispatch("data/loadRegions");
    }
    for (const region of this.$store.getters["data/regions"]) {
      if (region.country.name === "РОССИЯ") {
        this.groupedCities[0].items.push({
          label: region.name,
          value: region.name,
          filledRegion: region,
        });
      } else {
        this.groupedCities[1].items.push({
          label: region.name,
          value: region.name,
          filledRegion: region,
        });
      }
    }
  },
  computed: {
    companies() {
      return this.$store.getters["myprofiles/clients"];
    },
    countryList() {
      return this.$store.getters["data/countries"];
    },
    regionList() {
      if (this.country.val) {
        return this.$store.getters["data/regions"].filter(
          (r) => r.country.uid === this.country.val.uid
        );
      }
      return [];
    },
    roleList() {
      // кроме сотрудников клиента, они теперь создаются в другом месте
      return this.$store.getters?.getRoleList?.filter(
        (r) => r.value !== "EMPLOYEE_OF_CLIENT"
      );
    },
  },
  watch: {
    selectedCompany: function (newValue, oldValue) {
      if (newValue) {
        this.companiesForProfile.push(newValue);
        this.selectedCompany = null;
      }
    },
  },
  methods: {
    deleteCompany(index) {
      this.companiesForProfile.splice(index, 1);
    },
    async loadCompanies() {
      this.isLoading = true;
      try {
        const response = await fetch(`${environment.apiUrl}/companies`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      this.name.isValid = true;
      this.lastname.isValid = true;
      this.email.isValid = true;
      this.phone.isValid = true;
      this.role.isValid = true;
      this.selectedGroupedCities.isValid = true;
      if (!this.name.val) {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (!this.lastname.val) {
        this.lastname.isValid = false;
        this.formIsValid = false;
      }
      if (!this.phone.val) {
        this.phone.isValid = false;
        this.formIsValid = false;
      }

      if (this.role.val?.value === ROLE.OPERATOR) {
        if (this.selectedGroupedCities.val.length === 0) {
          this.formIsValid = false;
          this.selectedGroupedCities.isValid = false;
        }
      } else {
        this.selectedGroupedCities.isValid = true;
      }

      if (this.role.val?.value === ROLE.ENGINEER) {
        if (!this.engineerLevel.val) {
          this.formIsValid = false;
          this.engineerLevel.isValid = false;
        } else {
          this.engineerLevel.isValid = true;
        }
      }

      if (!this.role.val) {
        this.role.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      if (this.email.val === "") {
        this.email.val = null;
      }

      let payloadRegions = [];

      for (const regions of this.selectedGroupedCities.val) {
        payloadRegions.push(regions.filledRegion);
      }

      const actionPayload = {
        firstname: this.name.val,
        lastname: this.lastname.val,
        email: this.email.val,
        phone: this.phone.val,
        role: this.role.val.value,
        regions: payloadRegions,
      };

      if (actionPayload.role === ROLE.OPERATOR) {
        actionPayload.extensionPhone = this.extensionPhone.val;
      }

      if (actionPayload.role === ROLE.SALES_MANAGER) {
        actionPayload.companies = this.companiesForProfile;
      }

      if (actionPayload.role === ROLE.ENGINEER) {
        actionPayload.dep = this.dep.val;
        actionPayload.engineerLevel = this.engineerLevel.val;
      }

      try {
        await this.$store.dispatch("myprofiles/createUser", actionPayload);
        await this.$router.push("/");
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Профиль ${actionPayload.lastname} ${actionPayload.firstname} создан!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось создать профиль";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при создании профиля!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
<style>
.delete-button {
  max-width: 1.1rem;
  height: 1.12rem;
  padding: 0;
  margin: 0;
}
</style>
